import { TradeScreen } from "@screens/Trade";

export interface ITradeScreen {
    layout: string;
}

export async function getStaticProps() {
    return {
        props: {
            layout: "trade",
        },
    };
}

export default function App(props: ITradeScreen) {
    return <TradeScreen {...props} />;
}
